.cursor--pointer {
  cursor: pointer;
}

.display--block {
  display: block;
}

.object-fit {
  &--cover {
    object-fit: cover;
  }

  &--contain {
    object-fit: contain;
  }
}

.background-transparent {
  background: transparent;
}

.position--relative {
  position: relative;
}

.text {
  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  &--decoration {
    text-decoration: line-through;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--capitalize {
    text-transform: capitalize;
  }

  &--decoration-none {
    text-decoration: none;
  }
}

.width {
  &--full {
    width: 100%;
  }

  &--inherit {
    width: inherit;
  }

  &--fit-content {
    width: fit-content;
  }

  &--fill-available {
    width: -webkit-fill-available;
  }

  &--auto {
    width: auto;
  }

  &--vw {
    width: 100vw;
  }
}

.height {
  &--full {
    height: 100%;
  }

  &--fit-content {
    height: fit-content;
  }
}

.border{
  &--input fieldset {
    border-radius: 8px;
  }
}

.primary-border {
  position: relative;
  background: white;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 16px;

  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    border-radius: inherit;
    margin: -2px;
    background: linear-gradient(180deg, rgba(238, 205, 163, 1) 0%, rgba(239, 98, 159, 1) 100%) 10%;
  }
}

.border-primary-left {
  border-left: 4px solid;
  border-image: linear-gradient(180deg, rgba(238, 205, 163, 1) 0%, rgba(239, 98, 159, 1) 100%) 10%;
}

.primary-color {
  color: #EF629F;
}

.filter-brightness-light {
  filter: brightness(0.5);
}

.background-black-light {
  background-color: rgba(0, 0, 0, 0.5);
}

.height--full-vh {
  height: 100vh;
}
.mx--20 {
  margin-right: -20px;
  margin-left: -20px;
}

.mx--40 {
  margin-right: -40px;
  margin-left: -40px;
}

.mx--24 {
  margin-right: -24px;
  margin-left: -24px;
}

.mt--20 {
  margin-top: -20px;
}

.mb--3 {
  margin-bottom: -3px;
}

.line-height--24 {
  line-height: 24px;
}

.line-height--32 {
  line-height: 32px;
}
