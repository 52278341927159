.icon-button {
  &.MuiButtonBase-root {
    padding: 4px 0 4px 4px;
    min-width: fit-content;
    background-color: transparent;
    box-shadow: unset;
  }
}

.custom-select {
  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  .MuiSelect-select {
    padding: 0;
  }
}

.custom-tab {
  .MuiTabs-scroller {
    .MuiButtonBase-root {
      color: white !important;
    }
  }
}

.custom-checkbox, .MuiFormControlLabel-label {
  &.MuiCheckbox-root  {
    padding: 0 12px 0 0 !important;
  }
  font-weight: 400;
  color: #343E56;
}

.terms-condition {
  .custom-checkbox, .MuiFormControlLabel-label {
    font-size: 16px;
    font-weight: 600;
  }
}

html, body {
  height: 100%;
  background-color:  #F8F9FA;

  @media screen and (min-width: 200px) and (max-width:700px) {
    padding-right: 0 !important;
  }
}

.MuiAccordion-root {
  border-radius: 8px !important;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
  &:before {
    height: unset;
  }

  .MuiCollapse-vertical {
    z-index: 1;
  }
}

.MuiAccordionSummary-root.Mui-expanded {
  background-color: #75829C;
  border-radius: 4px 4px 0 0;

  p {
    color: white;
  }
  svg {
    color: white;
  }
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}

.MuiFormControlLabel-root {
  margin: 0;
}

.comment-action {
  background-color: #EF629F;

  .white-icon {
    color: white;
  }
}

.comment-action-laptop {
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.08);
  transition: 0.35s;

  &:hover {
    background-color: #EF629F;

    .white-icon {
      color: white;
    }
  }
}

.custom-swiper-pagination {
  .swiper-pagination {
    bottom: 0 !important;
  }

  .swiper-pagination-bullet-active {
    background-color: #343E56;
  }

  .swiper-pagination-bullet {
    height: 12px;
    width: 12px;
  }

  .swiper-wrapper {
    align-items: center;
  }
}

.home-page-pagination {
  .swiper-pagination-bullet-active {
    background-color: #EF629F !important;
  }

  .swiper-pagination-bullet {
    height: 12px;
    width: 12px;
    background-color: white;
  }
}

.MuiTableRow-head {
  background-color: #75829C;

  .MuiTableCell-head {
    color: white;
  }
}
