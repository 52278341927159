/* You can add global styles to this file, and also import other style files */

@use 'styles/mixins/index' as *;
@use 'styles/utilities' as *;
@use 'styles/overwrite' as *;
@use 'styles/common' as *;

* {
  margin: 0;
  scroll-behavior: smooth;
  font-family: 'Montserrat', sans-serif;
}

.main-container {
  height: 100%;
}

body {
  overflow: scroll;
}
