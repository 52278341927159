.action-hover-button {
  transition: all 0.35s;

  &:hover {
    background: #ffffff;
    box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
  }
}

.action-selected-button {
  background: #ffffff;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.main-background {
  background-color: #f8f9fa;
}

.MuiTabs-scroller {
  .MuiButtonBase-root {
    padding: 16px 32px;

    @media screen and (min-width: 200px) and (max-width: 700px) {
      margin-left: -32px;
    }

    margin-right: 16px;
    text-transform: capitalize;
    font-size: 16px;
    color: #75829c;
    font-weight: 400;

    &.Mui-selected {
      color: #343e56;
      font-weight: 700;
    }
  }
}

.text-area {
  width: calc(100% - 18px);
  font-size: 16px;
}

.text-area:focus-visible {
  outline-color: #ef629f;
}

.custom-search-input {
  .MuiInputBase-root {
    background: #f8f9fa;
    border-radius: 60px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  .MuiInputBase-input {
    padding: 12px;
  }
}

.card-shadow {
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.08);
}

.swiper-button-next,
.swiper-button-prev {
  font-size: 26px !important;
  color: white !important;

  &:after {
    font-size: 26px !important;
  }
}

.loader {
  animation: rotation 9s infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(359deg);
  }
  75% {
    transform: rotate(250deg);
  }
  75% {
    transform: rotate(0deg);
  }
}

a {
  text-decoration: none;
  color: #ef629f;
}

.card-sub-title {
  color: #343e56;
}

.MuiToggleButtonGroup-root {
  background-color: white;
  border-radius: 16px;

  .Mui-selected {
    margin: 12px;
    background-color: white;
    font-weight: 600;
    border-radius: 8px !important;
    border: 1.5px solid #ef629f !important;
    color: #343e56;
  }

  .MuiButtonBase-root {
    border: unset;
    font-size: 14px;
    margin: 8px;
    color: rgba(117, 130, 156, 1);
    text-transform: capitalize;

    &:hover {
      background-color: unset;
    }
  }
}

.card-graident {
  background: linear-gradient(180deg, #eecda3 0%, #ef629f 100%);
}

.card--height {
  min-height: 254px;
}

.text-ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.published--card {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.width--available {
  width: -webkit-fill-available;
}

.MuiFormHelperText-root {
  color: #e74c3c;
}

.MuiTabs-flexContainer {
  justify-content: center;

  @media screen and (min-width: 200px) and (max-width: 775px) {
    justify-content: left;
  }
}

.auth-container {
  .MuiTabs-scroller {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .MuiTabs-flexContainer {
    display: flex;
    justify-content: space-between;
    width: 40%;

    .Mui-selected svg {
      color: #ef629f;
    }

    .MuiButtonBase-root {
      min-width: fit-content;
      max-width: fit-content;
    }

    .MuiTabs-scroller .MuiButtonBase-root {
      padding: 0;
    }
  }
}

.character {
  border: none;
  font-size: 20px;
  font-weight: bold;
  border-radius: 8px;
  color: #272729;
  background-color: #f6f5fa;
  box-shadow: 0 2px 0 #e4e2f5;

  &--selected {
    color: #ef629f;
    outline-color: #ef629f;
  }
}

.half-khorlo-reflect {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.home-news-event {
  overflow-y: auto;
  height: calc(100vh - 350px);
}

.faq-scroll-list {
  height: calc(100vh - 215px);
  overflow-y: auto;
}

.footer-background {
  background: linear-gradient(
      0deg,
      rgba(52, 62, 86, 0.98),
      rgba(52, 62, 86, 0.98)
    ),
    url('apps/naykab-gokab/src/assets/images/footer-background.svg');
}

.explorer-background {
  background: url('apps/naykab-gokab/src/assets/images/idea-background.svg');
  background-size: contain;
}

.accessibility,
.accountability,
.adolescents,
.digital_product,
.digital_solution_for_health,
.youth_empowerment,
.female_employment,
.\33 d_printing {
  background-color: rgba(211, 47, 125, 0.6);
}

.digital_solution_for_health_public_sector_innovation_e_service,
.public_sector_innovation,
.fintech,
.private_sector_support_renewable_energy,
.digital_services,
.d_printing,
.access_to_basic_services,
.access_to_education,
.tested_and_proven,
.agriculture,
.sustainable_organic_farming {
  background-color: #76d03f;
}

.adventure_tourism,
.affordable_energy,
.affordable_housing,
.agile_management,
.aging,
.ideation,
.social_enterprise,
.livelihood {
  background-color: #f39c12;
}

.agricultural_mechanization,
.agricultural_waste,
.agritourism,
.alternative_energy,
.artificial_intelligence,
.augmented_reality,
.behavioral_insights,
.big_data,
.biomimicry,
.digital_technology {
  background-color: #75829c;
}

.bio_waste,
.biotechnology,
.carbon_emission,
.circular_economy,
.citizen_engagement_and_feedback,
.citizen_science,
.civic_participation,
.prototype,
.rural_development {
  background-color: #e0b333;
}

.clean_cities,
.clean_energy,
.climate_change,
.community_empowerment,
.community_engagement,
.connectivity,
.zero_waste,
.waste_management,
.crowdfunding,
.recycling,
.livestock,
.agritech {
  background-color: #76d03f;
}

.data_science,
.digital_economy,
.digital_public_goods,
.digital_platform,
.digital_solution,
.disabilities,
.disaster_risk_reduction,
.waste,
.test,
.public_services,
.solar_energy {
  background-color: #eecda3;
}

.e_commerce,
.e_governance,
.emerging_technologies,
.employment,
.youth_employment,
.youth_engagement,
.youth,
.energy,
.transportation_mobility,
.education,
.business_trade_finance {
  background-color: #72dcfd;
}

.entrepreneurship,
.food_security,
.gamification,
.gender_equality,
.governance,
.green_technology,
.housing,
.remote_work,
.partnership {
  background-color: #da8962;
}

.informal_business,
.inclusive_growth,
.innovation,
.innovation_challenges,
.innovative_financing,
.investment,
.data_management,
.digital_participation,
.local_government,
.startup,
.sustainability {
  background-color: #f39c12;
}

.msmes,
.digital_forms,
.nature_based_solutions,
.participatory_design,
.hackathons,
.systems_thinking,
.design_thinking,
.process_innovation,
.plastic_waste_management,
.partner,
.scale_up,
.human_wildlife_conflict,
.sustainable_energy {
  background-color: rgba(211, 47, 125, 0.6);
}

.top-innovators:nth-child(odd) {
  .innovators {
    width: 100px;
    height: 100px;

    @media screen and (min-width: 701px) and (max-width: 1199px) {
      width: 100px;
      height: 100px;
    }
  }

  .innovators-khader {
    width: 200px;

    @media screen and (min-width: 701px) and (max-width: 1199px) {
      width: 150px;
    }
  }

  .innovators-rank {
    width: 50px;
    height: 50px;
  }
}

.top-innovators:nth-child(even) {
  .innovators {
    width: 100px;
    height: 100px;
    @media screen and (min-width: 701px) and (max-width: 1199px) {
      width: 80px;
    }
  }

  .innovators-khader {
    width: 250px;

    @media screen and (min-width: 701px) and (max-width: 1199px) {
      width: 150px;
    }
  }

  .innovators-rank {
    width: 120px;
    height: 120px;
  }
}

.header-mobile {
  position: absolute;
  right: 16px;
  top: 16px;
}

.mx--16 {
  margin-left: -16px;
  margin-right: -16px;
}

.custom-form-input {
  min-height: 46px;
  font-size: 16px;
  padding-right: 8px;
  padding-left: 8px;
  width: calc(100% - 21px);

  &:focus-visible {
    outline-color: #ef629f;
  }
}

.overflow-wrap {
  word-break: break-word;
}

.custom-form-select {
  min-height: 52px;
  font-size: 16px;
  padding-right: 8px;
  padding-left: 8px;

  &:focus-visible {
    outline-color: #ef629f;
  }
}

.detail-image {
  img:nth-child(1) {
    height: 250px;
    width: 100%;
  }

  img:nth-child(2) {
    height: 250px;
    width: 100%;
  }

  img:nth-child(3) {
    height: 250px;
    width: 100%;
  }
}

.file-upload {
  position: relative;
  .file-upload-input {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}

.arrow-animation {
  animation: MoveUpDown 0.75s linear infinite;
  position: absolute;
}

@keyframes MoveUpDown {
  0%,
  100% {
    top: 20px;
  }
  50% {
    top: 30px;
  }
}

.zoom-image-slider {
  img {
    width: 100%;
    height: calc(100vh - 196px);
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: #ef629f !important;
    top: 96%;
  }

  .swiper-button-prev {
    left: 30%;
  }

  .swiper-button-next {
    right: 30%;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 40px !important;
  }
}
